<script>
import request from '@/found/utils/request';
import Storage from '@/found/utils/storage';
import Form, { formCreate } from '@/found/components/form';
import TableSelect from '../../quota_management/components/tableSelect.vue';

formCreate.component('TableSelect', TableSelect);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'dealer_quota_management_form',
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'quotaCode') {
        const paramsObj = {
          functionCode: 'select_quota',
          data: [],
          selectionList: [],
          idKey: 'quotaCode',
          noReset: true,
          paramData: { enableStatus: '009' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '配额编码',
        };
      }
      if (item.field === 'customerName') {
        const paramsObj = {
          functionCode: 'select_customer',
          data: [],
          selectionList: [],
          idKey: 'customerCode',
          noReset: true,
          paramData: { createPosCode: JSON.parse(Storage.s.get('userInfo')).positionCode },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '经销商',
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      if (this.formConfig.code === 'add') { // 默认为产品
        // this.setValue({
        //   productOrSeries: 'product',
        // });
        // this.fApi.hidden(true, ['seriesName', 'totalMoney', 'balanceMoney', 'applyMoney']);
        // this.fApi.hidden(false, ['productName', 'totalAmount', 'balanceAmount', 'applyAmount']);
      }
      this.disabled(true, ['quotaCode', 'year', 'quarter', 'companyName', 'productName', 'totalAmount', 'balanceAmount', 'productOrSeries', 'seriesName', 'totalMoney', 'balanceMoney']);
      const quotaCode = this.getRule('quotaCode');
      quotaCode.on.change = (val) => {
        this.setValue({ ...val });
        if (val.productOrSeries === 'product') {
          this.fApi.hidden(true, ['seriesName', 'totalMoney', 'balanceMoney', 'applyMoney']);
          this.fApi.hidden(false, ['productName', 'totalAmount', 'balanceAmount', 'applyAmount']);
          this.setValue({
            type: null,
            money: null,
          });
        } else if (val.productOrSeries === 'series') {
          this.fApi.hidden(true, ['productName', 'totalAmount', 'balanceAmount', 'applyAmount']);
          this.fApi.hidden(false, ['seriesName', 'totalMoney', 'balanceMoney', 'applyMoney']);
          this.setValue({
            type: null,
            amount: null,
          });
        }
      };
      const balanceAmount = this.getRule('balanceAmount');
      const customerName = this.getRule('customerName');
      customerName.on.change = (val) => {
        this.setValue({
          customerCode: val.customerCode,
          customerName: val.customerName,
          quotaCode: '',
          year: '',
          quarter: '',
          companyName: '',
          productName: '',
          totalAmount: '',
          balanceAmount: '',
          seriesName: '',
          totalMoney: '',
          balanceMoney: '',
        });
        quotaCode.props = {
          ...quotaCode.props,
          paramsObj: {
            functionCode: 'select_quota',
            data: [],
            selectionList: [],
            idKey: 'quotaCode',
            noReset: true,
            paramData: { companyCodes: val.mdmCustomerDockingVos.map((item) => item.ext2) || '', enableStatus: '009' },
          },
        };
        this.disabled(false, 'quotaCode');
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        if (this.formConfig.row.productOrSeries === 'product') {
          this.fApi.hidden(true, ['seriesName', 'totalMoney', 'balanceMoney', 'applyMoney']);
          this.fApi.hidden(false, ['productName', 'totalAmount', 'balanceAmount', 'applyAmount']);
        } else if (this.formConfig.row.productOrSeries === 'series') {
          this.fApi.hidden(true, ['productName', 'totalAmount', 'balanceAmount', 'applyAmount']);
          this.fApi.hidden(false, ['seriesName', 'totalMoney', 'balanceMoney', 'applyMoney']);
        }
        this.setValue({ ...this.formConfig.row });
        this.disabled(true, ['customerName']);
        // const url = '/mdm/mdmCustomerMsgExtController/pageList';
        // const params = { customerCode: this.formConfig.row.customerCode };
        // const res = await request.post(url, params);
        // if (res.success) {
        // const companyCode = res.result.data[0].mdmCustomerDockingVos[0]?.ext2 || '';
        quotaCode.props = {
          ...quotaCode.props,
          paramsObj: {
            functionCode: 'select_quota',
            data: [],
            selectionList: [],
            idKey: 'quotaCode',
            noReset: true,
            paramData: { companyCode: this.formConfig.row.companyCode },
          },
        };
        const quotaRes = await request.post('/dms/dms/companyQuota/pageList', {
          companyCode: this.formConfig.row.companyCode,
          pageNum: 1,
          pageSize: 15,
          quotaCode: this.formConfig.row.companyQuotaCode,
        });
        if (quotaRes.success) {
          this.setValue({ ...quotaRes.result.data[0] });
        }
        this.disabled(false, 'quotaCode');
        // }
      }
    },

    // 表单提交
    submit() {
      const formData = this.getFormData();
      formData.ratio *= 1;
      if ((formData.applyAmount > formData.balanceAmount) || (formData.applyMoney > formData.balanceMoney)) {
        this.$message.error('申请配额不能大于配额余量');
        return;
      }
      if (formData.amount) {
        const isRato = formData.applyAmount % formData.ratio;
        if (isRato !== 0 && formData.ratio !== 0) {
          this.$message.error('配额总量必须为产品包装规格整数倍');
          return;
        }
      }
      if (formData) {
        let url = '/dms/dms/customerQuota/save';
        const params = { ...formData, ratio: formData.ratio };
        params.companyQuotaCode = formData.quotaCode;
        delete params.quotaCode;
        if (this.formConfig.code === 'edit') {
          url = '/dms/dms/customerQuota/update';
          params.quotaCode = this.formConfig.row.quotaCode;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
