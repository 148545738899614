<script>
import TablePage from '@/found/components/table_page';
import Form from '../adjustment';

export default {
  extends: TablePage,
  components: { Form },
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { quotaCode: this.propsObj.row.quotaCode, id: this.propsObj.row.id };
    this.getConfigList('dealer_quota_management_detail');
  },
  methods: {
    // 行按钮隐藏
    clickVisible({ val, row }) {
      if (row.auditStatus === '4' && val.buttonCode === 'edit') {
        return true;
      }
      return false;
    },
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit') {
        this.formName = 'Form';
        this.modalConfig.title = '经销商配额';
        this.modalConfig.width = '40%';
        this.formConfig.row = this.propsObj.row;
        this.formConfig.code = val.code;
        this.openModal();
      }
    },
  },
};
</script>
