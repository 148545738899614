<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'dealer_quota_management_adj',
      formParentCode: 'CRM20220328000000003',
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;

      // console.log(111111111111111, v, this.formConfig.code);
      // if (item.field === 'type' && this.formConfig.code === 'add') {
      if (item.field === 'type') {
        item.options = [
          {
            label: '配额调增',
            value: '2',
          },
          {
            label: '配额调减',
            value: '3',
          },
          // {
          //   label: '配额提前（增加）',
          //   value: '6',
          // },
        ];
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      if (this.formConfig.row.productOrSeries === 'product') {
        this.fApi.hidden(true, ['seriesName', 'totalMoney', 'applyMoney', 'usedMoney', 'balanceMoney', 'money']);
        this.fApi.hidden(false, ['productName', 'totalAmount', 'applyAmount', 'usedAmount', 'balanceAmount', 'amount']);
      } else if (this.formConfig.row.productOrSeries === 'series') {
        this.fApi.hidden(true, ['productName', 'totalAmount', 'applyAmount', 'usedAmount', 'balanceAmount', 'amount']);
        this.fApi.hidden(false, ['seriesName', 'totalMoney', 'applyMoney', 'usedMoney', 'balanceMoney', 'money']);
      }
      this.disabled(true, [
        'quotaCode',
        'year',
        'quarter',
        'companyName',
        'productName',
        'totalAmount',
        'usedAmount',
        'balanceAmount',
        'applyAmount',
        'productOrSeries',
        'seriesName',
        'totalMoney',
        'applyMoney',
        'usedMoney',
        'balanceMoney',
      ]);
      if (this.formConfig.row.urlParam) {
        this.display(false, 'applyAmount');
        const url = '/dms/dms/customerQuotaDetail/query';
        const params = {
          id: this.formConfig.row.id,
        };
        const res = await request.get(url, params);
        const type = this.getRule('type');
        type.options = [
          {
            label: '配额调增',
            value: '2',
          },
          {
            label: '配额调减',
            value: '3',
          },
          {
            label: '配额提前（增加）',
            value: '6',
          },
          {
            label: '配额申请',
            value: '8',
          },
        ];
        if (res.result.productOrSeries === 'product') {
          this.fApi.hidden(true, ['seriesName', 'totalMoney', 'applyMoney', 'usedMoney', 'balanceMoney', 'money']);
          this.fApi.hidden(false, ['productName', 'totalAmount', 'applyAmount', 'usedAmount', 'balanceAmount', 'amount']);
        } else if (res.result.productOrSeries === 'series') {
          this.fApi.hidden(true, ['productName', 'totalAmount', 'applyAmount', 'usedAmount', 'balanceAmount', 'amount']);
          this.fApi.hidden(false, ['seriesName', 'totalMoney', 'applyMoney', 'usedMoney', 'balanceMoney', 'money']);
        }
        this.setValue({ ...res.result, quotaCode: res.result.customerQuotaCode });
      } else {
        this.setValue({ ...this.formConfig.row });
      }
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      formData.ratio *= 1;
      if ((formData.type === '3' && formData.balanceAmount < formData.amount * 1) || (formData.type === '3' && formData.balanceMoney < formData.money * 1)) {
        return this.$message.error('配额调减不能大于剩余数量！');
      }
      if (formData.amount) {
        const isRato = formData.amount % formData.ratio;
        if (isRato !== 0 && formData.ratio !== 0) {
          this.$message.error('配额总量必须为产品包装规格整数倍');
          return;
        }
      }
      if (formData) {
        const url = '/dms/dms/customerQuota/change';
        const params = {
          amount: formData.amount,
          quotaCode: formData.quotaCode,
          type: formData.type,
          ratio: formData.ratio,
          money: formData.money,
          productOrSeries: formData.productOrSeries,
          remarks: formData.remarks,
        };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
